#id-style {
  border-size: 0px;
  border-style: solid;
}

#nav {
  font-size: 14;
}

#imgDiv {
  margin-left: 150px;
  border-style: dashed;
}

.lit {
  background-color: black;
}

.dark {
  background-color: white;
}

h3 {
  font-weight: 600;
  letter-spacing: 1px;
  font-family: 'Source Sans Pro';
}

.jumbotronSubs a {
  color: #fff;
  text-decoration:underline;
}

.jumbotronSubs a:hover {
  color: #337a09;
  text-decoration: none;
}

a:hover {
  color: #4ac308;
  text-decoration: none;
}

.navbarStyle {
  background-color: white;
}


.jumbotronHeader {
  color: white;
  font-weight: 600;
  font-size: 34px;
  letter-spacing: 1px;
  font-family: 'Source Sans Pro';
}

.jumbotronText {
  color: white;
  text-align: justify;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  font-family: 'Source Sans Pro';
}

.navbarTextStyle {
  color: #4f4f4f;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1.2px;
  font-family: 'Source Sans Pro';
}

.navbarHeadingStyle {
  color: #4f4f4f;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 1.2px;
  font-family: 'Source Sans Pro';
}

.headerSubs{
  font-size: 12px !important;
  padding-left:0 !important; 
  padding-top: 10px !important;
  line-height: 15px !important;
}

.headerSubs p{
  margin-bottom: 0.75em !important;
}

.descriptionStyleMaterialTaxonomy {
  text-align: justify;
  color: #4f4f4f;
  line-height: 1.8rem;
  font-family: 'Source Sans Pro';
  font-size: 18px;
}

.tabelTextStyle {
  text-align: justify;
  line-height: 1.8rem;
  font-family: 'Source Sans Pro';
  font-size: 14px;
}

.numberList li::before {
  content: counter(count-me) ". ";
  display: block;
  position: relative;
  max-width: 0px;
  max-height: 0px;
  left: -1.3em;
  top: .0em;
  color: #007bff;
}

.numberList {
  list-style-type: none;
}

.numberList li {
  counter-increment: count-me;
}

.buttonTextStyle {
  color: #4ac308;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.2px;
  font-family: 'Source Sans Pro';
  background-color: white;
  border-color: #4ac308;
  border-style: solid;
  border-width: 1.45px;
}

.buttonTextStyle:hover {
  color: white;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.2px;
  font-family: 'Source Sans Pro';
  background-color: #4ac308;
  border-color: #4ac308;
  border-style: solid;
}

.dropDownButtonStyle {
  color: white;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: 'Source Sans Pro';
  background-color: #4ac308;
  border-color: #4ac308;
  border-style: solid;
  border-width: 1.45px;
  outline: none;
}

.dropDownMenuItemStyle {
  color: #4f4f4f;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-family: 'Source Sans Pro';
  border-style: solid;
  outline: none;
}

.dropDownButtonStyle:active, .dropDownButtonStyle::after, .dropDownButtonStyle:focus  {
  outline: none;
  text-decoration: none;

}

.show>.btn-secondary.dropdown-toggle:focus {
  outline: none !important;
  box-shadow: 0 0 0 0rem rgba(108,117,125,.0);
  text-decoration: none;
}

.btnOptions:active {
  background-color: #4ac308;
  outline: none;
  text-decoration: none;
  box-shadow: none !important;
}

.btn:focus, .btn:active:focus, .btn.active:focus {
  outline: none !important;
  text-decoration: none;
  box-shadow: none !important;
}

.cardHeadingStyle {
  color: #4f4f4f;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 1.2px;
  font-family: 'Source Sans Pro';
}

.cardTextStyle {
  color: #4f4f4f;
  font-weight: 400;
  font-size: 15px;
  font-family: 'Source Sans Pro';
  line-height: 1.5;
  text-align: justify;
}

.mapInfoWindowTextStyle {
  color: #4f4f4f;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Source Sans Pro';
  letter-spacing: 1;
  text-align: justify;
}

.tabelHeadingTextStyle {
  color: #4f4f4f;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 1.2px;
  font-family: 'Source Sans Pro';
  padding-bottom: 1em;
}

.cardStyle {
  box-shadow: 0 6px 100px 0 rgba(0, 0, 0, 0.1), 0 6px 8px 0 rgba(0, 0, 0, 0.1);
}

h5 {
  color: #4f4f4f;
  font-weight: 600;
  font-family: 'Source Sans Pro';
  line-height: 1.5;
  text-align: justify;
}

.graphText {
  font-family: 'Source Sans Pro';
  font-weight: 600;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.materialHeadingStyle {
  color: #4f4f4f;
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 1px;
  font-family: 'Source Sans Pro';
  background-color: white;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.footerTextStyle {
  font-family: 'Source Sans Pro';
  font-size: 13.5px;
  color: white;
  text-align: center;
  /* padding-top: 60px; */
}

.iconStyle {
  color: white;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 15px;
}

.copyrightIconStyle {
  color: white;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 8px;
}

.containerJumbotronPlastic {
  position: relative;
  text-align: center;
  color: white;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./images/Plastic.jpg);
}

.containerJumbotronPaper {
  position: relative;
  text-align: center;
  color: white;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./images/Paper.jpg);
}

.containerJumbotronMetal {
  position: relative;
  text-align: center;
  color: white;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./images/Metal.jpg);
}

.containerJumbotronGlass {
  position: relative;
  text-align: center;
  color: white;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./images/Glass.jpg);
}

.containerJumbotronHome {
  position: relative;
  text-align: center;
  color: white;
  height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./images/Home.jpg);
}

.containerJumbotronStakeholder {
  position: relative;
  text-align: center;
  color: white;
  height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./images/Stakeholders.jpg);
}

.containerJumbotronMaterials {
  position: relative;
  text-align: center;
  color: white;
  height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./images/Materials.jpg);
}

.containerJumbotronMetal {
  position: relative;
  text-align: center;
  color: white;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./images/Metal.jpg);
}

.imgContainer {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, rgba(82,198,19,1), rgba(82,198,19,0.55));
}

h3{
  color: #4ac308;
  text-align: justify;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 15px;
  letter-spacing: 1.2px;
}

#map {
  color: #4ac308;
  text-align: center;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  letter-spacing: 1.2px;
  line-height: 2;
}

button:focus {
  outline: 1px;
  /* outline: 5px auto -webkit-focus-ring-color; */
}

@media screen and (max-width: 768px) {
  
  .descriptionHeaderViewStyle {
    margin: 0.5rem 0.5rem;
  }
  
  .centered {
    position: absolute;
    bottom: 5%;
    color: white;
    text-align: justify;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-family: 'Source Sans Pro';
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
    display: flex;
    flex-direction: column;
  }
  
  .jumbotronHeader {
    color: white;
    font-weight: 600;
    font-size: 23px;
    letter-spacing: 1px;
    font-family: 'Source Sans Pro';
    padding-bottom: 10px;
  }
  
  .descriptionStyle {
    text-align: justify;
    color: #4f4f4f;
    line-height: 1.8rem;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .headerSubs{
    font-size: 10px;
  }
  
  .listStyle {
    text-align: justify;
    color: #4f4f4f;
    line-height: 1.8rem;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    padding-right: 15px;
  }
  
}

@media screen and (max-width: 576px) { 
  .descriptionStyle {
    text-align: justify;
    color: #4f4f4f;
    line-height: 1.8rem;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .jumbotronHeader {
    color: white;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
    font-family: 'Source Sans Pro';
    padding-bottom: 10px;
  }
  
  .headerSubs p{
    font-size: 10px !important;
    margin-bottom: 0.5em !important;
  }
  
  
  .descriptionHeaderViewStyle {
    margin: 0.5rem 0.5rem;
  }
  
  .descriptionHeaderViewStyle p{
    font-size: 12px;
    margin-bottom: 0;
  }
  
  .centered {
    position: absolute;
    bottom: 5%;
    color: white;
    text-align: justify;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-family: 'Source Sans Pro';
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .listStyle {
    text-align: justify;
    color: #4f4f4f;
    line-height: 1.8rem;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 768px) {
  .descriptionViewStyle {
    margin: 1rem 3rem;
  }
  .descriptionHeaderViewStyle {
    margin: 1rem 3rem;
  }
  .jumbotron {
    padding: 2rem 10rem;
  }
  .descriptionStyle {
    text-align: justify;
    color: #4f4f4f;
    line-height: 1.8rem;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .centered {
    position: absolute;
    bottom: 5%;
    color: white;
    text-align: justify;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-family: 'Source Sans Pro';
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .listStyle {
    text-align: justify;
    color: #4f4f4f;
    line-height: 1.8rem;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .descriptionViewStyle {
    margin: 2rem 10rem;
  }
  .descriptionHeaderViewStyle {
    margin: 2rem 10rem;
  }
  .jumbotron {
    padding: 2rem 10rem;
  }
  .descriptionStyle {
    text-align: justify;
    color: #4f4f4f;
    line-height: 1.8rem;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .centered {
    position: absolute;
    bottom: 5%;
    color: white;
    text-align: justify;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-family: 'Source Sans Pro';
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .listStyle {
    text-align: justify;
    color: #4f4f4f;
    line-height: 1.8rem;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    padding-right: 15px;
  }
}
